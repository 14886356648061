module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/zeit/17b9d3f7/src/layouts/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Richter","short_name":"David Richter","start_url":"/","background_color":"#171717","theme_color":"#3b6a6f","display":"standalone","include_favicon":false,"icon":"src/icons/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/arbeiten","/impressum","/datenschutz","/arbeiten/*",""]},
    }]
