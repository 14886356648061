import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Iframe from 'react-iframe'

import "../styles/index.sass";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const TemplateWrapper = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const year = new Date().getFullYear()
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            introTextNode {
              childMarkdownRemark {
                html
              }
            }
            copyright 
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
        }
      `}
      render={data => (
        <div className={`container ${showMenu ? "is-open" : ""}`}>
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
          />
          <div className="container__sidebar">
            <div className="sidebar">
              <div className="sidebar__top">
                <div className="close__wrapper">
                  <button
                    aria-label="closeMenu"
                    href="#"
                    className="close"
                    onClick={e => {
                      e.preventDefault();
                      setShowMenu(false);
                    }}
                  />
                </div>
                <h6 className="sidebar__title">
                  <Link to="/" onClick={e => {
                    setShowMenu(false);
                  }}>{data.datoCmsSite.globalSeo.siteName}</Link>
                </h6>
                <ul className="sidebar__menu">
                  <li>
                    <Link to="/" onClick={e => {
                      setShowMenu(false);
                    }}>Home</Link>
                  </li>
                  <li>
                    <Link to="/arbeiten" onClick={e => {
                      setShowMenu(false);
                    }}>Arbeiten</Link>
                  </li>
                  <li>
                    <Link to="#form" onClick={e => {
                      setShowMenu(false);
                    }}>Kontakt</Link>
                  </li>
                  <li>
                    <Link to="/impressum" onClick={e => {
                      setShowMenu(false);
                    }}>Impressum</Link>
                  </li>
                  <li>
                    <Link to="/datenschutz" onClick={e => {
                      setShowMenu(false);
                    }}>Datenschutz</Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar__bottom">
                <h4>Hier ein Techno Set:</h4>
                <Iframe
                  url="https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fdavidrichter16547%2Fnice-person-bad-vibes-glashaus-130718%2F"
                  width="260px"
                  height="120px"
                  id="mixcloud-set"
                  display="initial"
                  position="relative" />
                <p className="sidebar__social">
                  {data.allDatoCmsSocialProfile.edges.map(({ node: profile }) => (
                    <a
                    key={profile.profileType}
                    href={profile.url}
                    aria-label={profile.profileType}
                    target="blank"
                    className={`social social--${profile.profileType.toLowerCase()}`}
                    >
                      {" "}
                    </a>
                  ))}
                </p>
                <div className="sidebar__copyright">
                  &copy;{year} {data.datoCmsHome.copyright}
                </div>
                </div>
            </div>
          </div>
          <div className="container__body" id="container__body">
            <div className="container__mobile-header">
              <div className="mobile-header">
                <div className="mobile-header__menu">
                  <button
                    aria-label="showMenu"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setShowMenu(!showMenu);
                    }}
                  />
                </div>
                <div className="mobile-header__logo">
                  <Link to="/" onClick={e => {
                    setShowMenu(false);
                  }}>{data.datoCmsSite.globalSeo.siteName}</Link>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      )}
    />
    );
  };

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;